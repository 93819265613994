@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  font-size: 3em;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;

  // increase spinning speed
  svg {
    animation: spin 0.8s infinite linear;
    border-radius: 50%;
    padding: 0.2em;
  }
}
